import { GetSelectLookup } from "./common";

const ServiceTypeEnum = {
  All: 0,
  Residental: 1,
  Respite: 2,
  Convalescence: 3,
  DayCare: 4,
  SupportedLiving: 5,
  TransitionalCare: 6,
  HomeSupport: 7
};

const ServiceTypLookup = ["", "Residental", "Respite", "Convalescence", "Day Care", "Support Living", "Transitional Care", "Home Support"];

export default {
  Enum: ServiceTypeEnum,
  SelectLookup: GetSelectLookup(ServiceTypeEnum, ServiceTypLookup),
  Lookup: ServiceTypLookup
};
