<template>
  <b-tooltip :label="title" :position="tooltipPosition" type="is-primary is-light">
    <router-link v-if="!!routeTo" :to="routeTo" class="button is-small is-primary">
      <b-icon :icon="Icons[icon] || icon" :disabled="disabled" size="is-small" />
    </router-link>
    <a v-else :class="{ 'card-header-icon': !noPadding }" @click.stop="clicked">
      <b-icon :icon="Icons[icon] || icon" :disabled="disabled" :class="iconClass" />
    </a>
  </b-tooltip>
</template>

<script>
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  mixins: [IconsMixin],
  props: {
    icon: { type: String, require: true },
    title: { type: String, require: true },
    tooltipPosition: { type: String, require: false },
    disabled: { type: Boolean, require: false },
    routeTo: { type: Object },
    iconClass: { type: String },
    noPadding: { type: Boolean }
  },
  methods: {
    clicked() {
      if (!this.disabled) this.$emit("click");
    }
  }
};
</script>
