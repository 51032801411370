<template>
  <b-select
    :placeholder="placeholder"
    :value="value"
    @input="v => $emit('input', v)"
    :required="required"
    :disabled="disabled"
    :ref="selectRef"
    :class="{ smallWidth: smallWidth, mediumWidth: mediumWidth }"
  >
    <option v-for="option in allData" :value="option.id" :key="option.id">
      {{ option.value }}
    </option>
  </b-select>
</template>

<script>
export default {
  props: {
    data: { type: Array, default: () => [] },
    value: { type: Number },
    placeholder: { type: String },
    disabled: { type: Boolean },
    selectRef: { type: String },
    smallWidth: { type: Boolean },
    mediumWidth: { type: Boolean },
    required: { type: Boolean, default: true },
    includeAll: { type: Boolean, default: false },
    allValue: { type: Number, default: 0 }
  },
  computed: {
    allData() {
      if (this.data == null) return [];
      var data = [...this.data];

      if (this.includeAll) {
        data.unshift({ id: this.allValue, value: "<All>" });
      }
      return data;
    }
  }
};
</script>

<style scoped>
.smallWidth {
  max-width: 330px;
}
.mediumWidth {
  max-width: 500px;
}
</style>
