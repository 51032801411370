// For use in parent  with ContextTableCard component

import DatesMixin from "@/mixins/datesMixin";
import _ from "lodash";
import IconsMixin from "@/mixins/iconsMixin.js";
import { mapMutations } from "vuex";

export default {
  mixins: [DatesMixin],
  data() {
    return {
      isLoading: false,
      total: 0,
      tableData: [],
      selected: {}, // Individual selected, last one selected
      debounceRefreshData: _.debounce(this.refreshData, 300),
      // url: "", // set in parent component
      // entity: "" // set in parent component,
      headerIcons: [IconsMixin.headerIconRefresh()]
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    }
  },
  watch: {
    search: {
      immediate: false, // Initial call done on contextTableCare.Created, this allows for table to be hidden and no data retrieved until initially shown
      deep: true,
      handler(n, o) {
        // page changing triggeres getData, but we dont' want a refresh search also
        if (!this.isLoading) this.debounceRefreshData();
      }
    }
  },
  methods: {
    pageChange(page) {
      this.search.page = page;
      this.getData();
    },
    sort(field, order) {
      this.search.sortField = field;
      this.search.sortOrder = order;
      this.search.page = 1;
      this.getData();
    },
    refreshData() {
      //  some contextTables not doing the common seearch params,  don't auto refresh
      if (!this.search) return;
      this.search.page = 1;
      this.getData();
    },
    ...mapMutations("search", ["setSearch"]),
    async getData() {
      this.isLoading = true;
      // console.log("getdata", this.url, this.search);
      await this.$http
        .get(this.url, { params: this.search })
        .then(r => {
          this.total = r.data.total;
          this.tableData = r.data.tableData;

          // We save the search params in the Vuex store
          this.setSearch({
            url: this.url,
            search: this.search
          });
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading " + this.entity))
        .finally(() => (this.isLoading = false));
    },
    locationNameLookup(locationId, fallbackName, includeMainLocation, prefix) {
      return this.$store.getters["locations/locationName"](locationId, fallbackName, includeMainLocation, prefix);
    }
  }
};
