<template>
  <section class="section is-main-section" @mousedown="captureMouse">
    <div class="box">
      <div class="columns">
        <div class="column">
          <SelectLocation v-model="search.locationId" />
        </div>
        <div class="column">
          <b-field horizontal label="Name">
            <b-input v-model="search.name" />
          </b-field>
        </div>
        <div class="column">
          <b-field horizontal label="Service">
            <b-field>
              <EnumDropDown v-model="search.serviceType" :data="serviceType.SelectLookup" :required="false" :includeAll="true" />
            </b-field>
          </b-field>
        </div>
        <div class="column">
          <b-field horizontal label="Bed">
            <b-input v-model="search.roomBedName" />
          </b-field>
        </div>
        <div class="column">
          <b-field horizontal label="Status">
            <b-field>
              <EnumDropDown v-model="search.residentStatus" :data="residentStatusEnum.Select" :required="false" :includeAll="true" />
              <ClearSearch @clearSearch="clearSearch" />
            </b-field>
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <ContextTableCard
          title="Residents"
          icon="account-multiple"
          :header-icons="headerIcons"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          :tableData="tableData"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selected"
          @headerIconClicked="headerIconClicked"
          @menu-clicked="contextMenuClicked"
          :isLoading="isLoading"
          :select="true"
          :canEdit="canEditResident"
          @select="selectResident"
          @contextDefaultClicked="selectResident"
        >
          <b-table-column cell-class="has-no-head-mobile is-image-cell" v-slot="props">
            <div class="image">
              <img :src="props.row.avatarUrl" class="is-rounded" />
            </div>
          </b-table-column>
          <b-table-column label="Surname" field="surname" sortable v-slot="props">
            {{ props.row.lastName }}
          </b-table-column>
          <b-table-column label="First Name" field="firstname" sortable v-slot="props">
            {{ props.row.firstName }}
          </b-table-column>
          <b-table-column label="DOB" field="dob" sortable v-slot="props">
            {{ props.row.dob | dateFormat }}
          </b-table-column>
          <b-table-column label="Location" field="location" sortable v-slot="props">
            {{ props.row.location }}
          </b-table-column>
          <!-- <b-table-column label="Doctor" field="doctorsName" sortable v-slot="props">
            {{ props.row.doctorsName }}
          </b-table-column>
          <b-table-column label="Next of Kin" field="nextOfKin" sortable v-slot="props">
            {{ props.row.nextOfKin }}
          </b-table-column> -->
          <b-table-column label="Service" field="serviceType" sortable v-slot="props">
            {{ serviceType.Lookup[props.row.serviceType] }}
          </b-table-column>
          <b-table-column label="Status" field="status" sortable v-slot="props">
            {{ residentStatusEnum.Lookup[props.row.residentStatus] }}
          </b-table-column>
          <b-table-column label="Bed" field="currentBed" v-slot="props">
            {{ props.row.bedName }} {{ locationNameLookup(props.row.bedLocationId, null, false, " > ") }}
          </b-table-column>

          <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
            <div class="buttons is-right">
              <ClickIcon
                v-if="canEditResident"
                title="Edit Resident"
                :routeTo="{ name: 'resident.edit', params: { id: props.row.residentId } }"
                icon="edit"
              />

              <delete-object
                v-if="isAdmin"
                entity="Resident"
                :deleteName="props.row.fullName"
                :deleteId="props.row.residentId"
                url="residents"
                @deleted="deletedResident"
              />
            </div>
          </b-table-column>
        </ContextTableCard>
      </div>
    </div>

    <transition name="selectResident" @before-enter="beforeEnter" @after-leave="afterLeave">
      <table class="table selResident" ref="selResidentTable" v-if="changeSelectedResident">
        <tr>
          <td class="has-no-head-mobile is-image-cell">
            <div class="image">
              <img :src="selected.avatarUrl" class="is-rounded" />
            </div>
          </td>
          <td>
            {{ selected.fullName }}
          </td>
        </tr>
      </table>
    </transition>
  </section>
</template>

<script>
import ContextMenuOnTable from "@/mixins/contextMenuMixin";
import SelectLocation from "@/components/SelectLocation.vue";
import PermissionsEnum from "@/enums/permissions";
import { mapGetters } from "vuex";
import Icons from "@/mixins/iconsMixin.js";
import ResidentStatusEnum from "@/enums/residentStatus";
import ServiceType from "@/enums/serviceType";
import EnumDropDown from "@/components/EnumDropDown";
import ClearSearch from "@/components/ClearSearch.vue";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import ClickIcon from "@/components/ClickIcon.vue";
import DeleteObject from "@/components/DeleteObject.vue";

export default {
  components: { ContextTableCard, SelectLocation, EnumDropDown, ClearSearch, ClickIcon, DeleteObject },
  mixins: [ContextTableMixin, ContextMenuOnTable],
  data() {
    const url = "residents";
    const savedSearch = this.$store.getters["search/savedSearch"](url);

    const headerIcons = [Icons.headerIconRefresh()];
    if (this.$store.getters.hasPermission(PermissionsEnum.ResidentAdd)) {
      headerIcons.unshift(Icons.headerIconAdd("Resident"));
      headerIcons.unshift({ icon: Icons.icons.enquiry, name: "enquiry", title: "Add New Enquiry" });
    }

    return {
      url: url,
      entity: "Residents",
      changeSelectedResident: false, // handle resident selection
      newSelection: null,
      residentStatusEnum: ResidentStatusEnum,
      serviceType: ServiceType,
      headerIcons: headerIcons,
      search: {
        locationId: savedSearch.locationId,
        name: savedSearch.name,
        roomBedName: savedSearch.roomBedName,
        residentStatus: savedSearch.residentStatus ?? ResidentStatusEnum.Enum.Client,
        serviceType: savedSearch.serviceType ?? ServiceType.Enum.All,
        page: savedSearch.page ?? 1,
        sortField: savedSearch.sortField ?? "surname",
        sortOrder: savedSearch.sortOrder ?? "asc"
      }
    };
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    canEditResident() {
      return this.hasPermission(PermissionsEnum.ResidentUpdate);
    }
  },

  methods: {
    clearSearch() {
      this.search.locationId = null;
      this.search.name = null;
      this.search.roomBedName = null;
      this.search.residentStatus = ResidentStatusEnum.Enum.Client;
      this.search.serviceType = ServiceType.Enum.All;
    },
    async headerIconClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$router.push({ name: "resident.new" });
          break;
        case "refresh":
          await this.loadResidents();
          break;
        case "enquiry":
          this.$router.push({ name: "enquiry.new" });
          break;
      }
    },
    contextMenuClicked(event) {
      switch (event) {
        case "edit":
          this.editResident();
          break;
      }
    },

    editResident() {
      this.$router.push({ name: "resident.edit", params: { id: this.selected.residentId } });
    },

    deletedResident(id) {
      this.tableData = this.tableData.filter(r => r.residentId !== id);
    },

    // Select resident animation
    // on double click we show overlayed table, then hide it again using transition
    selectResident() {
      this.changeSelectedResident = true;
      this.newSelection = this.selected;
      setTimeout(() => {
        this.changeSelectedResident = false;
      }, 1);
    },
    // on showing table, move to mouse position
    beforeEnter(el) {
      el.style.top = this.mousedownEvent.screenY - 115 + "px";
    },
    // when transition done, we change selected user
    afterLeave(el) {
      this.$store.dispatch("resident/setResident", this.newSelection);

      // use direct query param or default to assessments
      let redirect = this.$route.query.redirect;
      if (!redirect || !this.$router.options.routes.find(r => r.name === redirect)) redirect = "assessments";
      this.$router.push({ name: redirect });
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.selResident {
  width: 75%;
  position: absolute;
  left: 250px;
  color: white;
  background-color: rgb(88, 71, 143);
  z-index: 100;
}
.selResident .is-image-cell {
  width: 100px;
}

.selectResident-leave-active {
  transition: all 0.6s ease-out;
}
.selectResident-leave-to {
  top: 0 !important;
  background-color: white;
  color: black;
  left: 220px;
}
</style>
