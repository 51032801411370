<template>
  <b-modal :active.sync="isModalActive" has-modal-card>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Confirm Delete {{ entity }}?</p>
      </header>
      <section class="modal-card-body has-text-danger">
        <b-icon icon="trash-can" size="is-large" class="is-pulled-left" />
        <p>
          This will permanently delete <b>{{ deleteName }}</b>
        </p>
        <p>This action can not be undone.</p>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click.stop="cancel">Cancel</button>
        <b-button class="button is-danger" @click.stop="confirm" :loading="isDeleting">Delete</b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalBox",
  props: {
    isActive: { type: Boolean, default: false },
    isDeleting: { type: Boolean, default: false },
    deleteName: { type: String, default: null },
    entity: { type: String, required: true }
  },
  data() {
    return {
      isModalActive: false
    };
  },
  watch: {
    isActive(newValue) {
      this.isModalActive = newValue;
    },
    isModalActive(newValue) {
      if (!newValue) {
        this.cancel();
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    }
  }
};
</script>
