<template>
  <div @mousedown="captureMouse">
    <card-component
      :title="title"
      :icon="icon"
      :header-icons="headerIcons"
      @header-icons-click="headerIconClicked"
      :class="{ hideHeader: hideHeader }"
    >
      <b-table
        class="rowClickable"
        :class="{ hideHeader: hideHeader }"
        :loading="isLoading"
        :paginated="paginated"
        backend-pagination
        :per-page="perPage"
        :total="total"
        @page-change="v => $emit('page-change', v)"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :hoverable="true"
        backend-sorting
        :default-sort="sortField"
        :default-sort-direction="sortOrder"
        @sort="(f, o) => $emit('sort', f, o)"
        :data="tableData"
        :columns="columns"
        :selected.sync="selectedRow"
        @click="showContextMenu"
        @dblclick="contextDefault"
        :row-class="rowClass"
        :checkable="checkable"
        :checked-rows="checkedRows"
        @check="(l, r) => $emit('check', l, r)"
      >
        <slot />

        <template v-slot:empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="isLoading">
                <LoadingData />
              </template>
              <template v-else>
                <NoData />
              </template>
            </div>
          </section>
        </template>
      </b-table>
      <p class="totalFooter" :class="{ onFooter: paginated }" v-if="total">Total: {{ total }}</p>
    </card-component>

    <ContextMenu
      v-if="!noMenu"
      ref="ctx"
      :menuOptions="menuOptions"
      @menu-clicked="v => $emit('menu-clicked', v)"
      :select="select"
      :edit="canEdit"
      @select="$emit('select')"
      @edit="editClicked"
    />
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import ContextMenu from "@/components/ContextMenu";
import NoData from "@/components/NoData.vue";
import LoadingData from "@/components/LoadingData.vue";
import ContextMenuMixin from "@/mixins/contextMenuMixin";

export default {
  emits: ["headerIconClicked", "contextDefaultClicked", "refreshData", "check"],
  components: { CardComponent, ContextMenu, NoData, LoadingData },
  mixins: [ContextMenuMixin],
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: false },
    headerIcons: { type: Array },
    tableData: { type: Array },
    columns: { type: Array },
    page: { type: Number, required: true },
    total: { type: Number, required: true },
    sortField: { type: String, required: true },
    sortOrder: { type: String, required: true },
    isLoading: { type: Boolean, required: true },
    rowClass: { type: [Function, String] },
    value: { type: Object, required: true },
    menuOptions: { type: Array, default: null },
    checkable: { type: Boolean },
    hideHeader: { type: Boolean },
    select: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: true },
    noMenu: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedRow: this.value,
      mousedownEvent: null,
      perPage: 25,
      checkedRows: []
    };
  },
  computed: {
    paginated() {
      return this.total > this.perPage;
    }
  },
  watch: {
    selectedRow(v) {
      this.$emit("input", v);
    }
  },
  methods: {
    headerIconClicked(iconName) {
      if (iconName === "refresh") this.refreshData();
      else this.$emit("headerIconClicked", iconName);
    },
    refreshData() {
      this.$emit("refreshData");
    },
    isSelectable() {
      return !this.checkable;
    },
    editClicked() {
      if (this.select) {
        this.$emit("select");
      } else {
        this.$emit("contextDefaultClicked");
      }
      this.closeContextMenu();
    }
  },
  created() {
    this.refreshData();
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/_theme.scss";

.totalFooter {
  position: relative;
  left: 30px;
  top: 10px;
  color: $primary;
  font-weight: 700;
}
.onFooter {
  top: -50px;
}
</style>
