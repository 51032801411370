import Dates from "@/common/dates";

export default {
  methods: {
    formatYYYYMMDD(date) {
      return Dates.formatYYYYMMDD(date);
    },
    dateOrNull(date) {
      return Dates.dateOrNull(date);
    },
    shortFormat(date) {
      return Dates.shortFormat(date);
    },
    shortFormatDateTime(date) {
      return Dates.shortFormatDateTime(date);
    },
    shortFormatDate(date) {
      return Dates.shortFormatDate(date);
    },
    formatDateTime(date) {
      return Dates.formatDateTime(date);
    },
    formatDateTimeRecent(date) {
      return Dates.formatDateTimeRecent(date);
    },
    daysSince(date, current) {
      return Dates.daysSince(date, current);
    },
    isFutureDate(date) {
      return Dates.isFutureDate(date);
    },
    today() {
      return Dates.today();
    }
  }
};
