<template>
  <div>
    <section>
      <card-component title="Organisations" icon="domain" header-icon="reload" @header-icon-click="loadOrgs">
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasOrgs">
          <li v-for="org in orgs" :key="org.organisationId">
            Name: {{ org.name }}
            <b> {{ org.address1 }}</b>
          </li>
        </ul>
        <h3 v-else>No organisations found.</h3>
      </card-component>
    </section>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";

export default {
  components: { CardComponent },
  data() {
    return {
      isLoading: false,
      error: null
    };
  },
  computed: {
    orgs() {
      return this.$store.getters["organisations/orgs"];
    },
    hasOrgs() {
      return !this.isLoading && this.$store.getters["organisations/hasOrgs"];
    }
  },
  created() {
    this.loadOrgs();
  },
  methods: {
    async loadOrgs(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("organisations/loadOrgs", {
          forceRefresh: refresh
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
