<template>
  <card-component>
    <div class="level is-mobile mb-0">
      <div class="level-item">
        <div class="is-widget-label">
          <p class="subtitle is-spaced is-size-6-mobile">
            {{ label }}
          </p>
          <p class="title is-size-4-mobile" :class="{ 'is-size-4': reducedSize }">
            <span v-if="useBoolValue">{{ boolValue ? lookup[1] : lookup[0] }} </span>
            <growing-number
              v-else
              :value="number"
              :prefix="prefix"
              :suffix="showNum2 ? '' : suffix"
              :hideNum="hideNum"
              :value2="number2"
              :lookup="lookup"
            />
            <span v-if="showNum2">
              /
              <growing-number :value="number2" :suffix="suffix" :lookup="lookup" />
            </span>
          </p>
          <p v-if="subFooter" class="is-size-7">{{ subFooter }}</p>
        </div>
      </div>
      <div v-if="icon || customIcon" class="level-item has-widget-icon">
        <div class="is-widget-icon">
          <b-loading v-model="loading" :is-full-page="false" />
          <b-icon v-if="icon" :icon="icon" size="is-large" :type="type" />
          <span v-if="customIcon" class="icon has-text-primary is-large">
            <img :src="'/img/icons/' + customIcon" />
          </span>
        </div>
      </div>
      <div v-if="showAdd" class="widgetAdd" @click.stop="$emit('add')" title="Add New">
        <b-icon :icon="Icons.add" size="is-default" type="is-primary" />
      </div>
      <div v-if="showGraph" class="widgetGraph" @click.stop="$emit('graph')" title="View Historical">
        <b-icon :icon="Icons.graph" size="is-default" type="is-primary" />
      </div>
    </div>
    <div v-if="footer1" class="level is-mobile is-size-7">
      <div class="level-item">{{ footer1 }}</div>
      <div class="level-item">{{ footer2 }}</div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import GrowingNumber from "@/components/GrowingNumber";
import IconsMixin from "@/mixins/iconsMixin";

export default {
  name: "CardWidget",
  mixins: [IconsMixin],
  components: { GrowingNumber, CardComponent },
  props: {
    icon: { type: String, default: null },
    customIcon: { type: String, default: null },
    number: { type: Number, default: 0 },
    hideNum: { type: Boolean, default: false },
    number2: { type: Number, default: 0 },
    showNum2: { type: Boolean, default: false },
    prefix: { type: String, default: null },
    suffix: { type: String, default: null },
    lookup: { type: Array, default: null },
    label: { type: String, default: null },
    type: { type: String, default: "is-primary" },
    subFooter: { type: String, default: null },
    footer1: { type: String, default: null },
    footer2: { type: String, default: null },
    loading: { type: Boolean, default: false },
    showAdd: { type: Boolean, default: false },
    showGraph: { type: Boolean, default: false },
    useBoolValue: { type: Boolean, default: false },
    boolValue: { type: Boolean },
    reducedSize: { type: Boolean }
  }
};
</script>

<style>
.widgetAdd {
  position: absolute;
  right: 20px;
  top: 13px;
}

.widgetGraph {
  position: absolute;
  right: 50px;
  top: 14px;
}
</style>
