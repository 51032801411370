<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :deleteName="deleteName"
      :entity="entity"
      @confirm="deleteConfirm"
      @cancel="deleteCancel"
      :isDeleting="isDeleting"
    />

    <ClickIcon :title="'Delete ' + entity" @click="showConfiramation" icon="delete" iconClass="has-text-danger" :disabled="disabled" />
  </div>
</template>

<script>
import ModalBox from "@/components/ModalBox";
import ClickIcon from "@/components/ClickIcon";

export default {
  props: {
    entity: { type: String, required: true },
    deleteName: { type: String, required: true },
    deleteId: { type: Number, required: true },
    disabled: { type: Boolean },
    url: { type: String, required: true }
  },
  components: { ModalBox, ClickIcon },
  data: () => ({
    isModalActive: false,
    isDeleting: false
  }),
  methods: {
    showConfiramation() {
      this.$emit("open");
      this.isModalActive = true;
    },
    async deleteConfirm() {
      this.isDeleting = true;
      await this.$http
        .delete(this.url + "/" + this.deleteId)
        .then(() => {
          this.$buefy.snackbar.open({ message: this.entity + " Deleted", queue: false });
          this.isModalActive = false;
          this.$emit("deleted", this.deleteId);
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error deleting " + this.entity))
        .finally(() => (this.isDeleting = false));
    },
    deleteCancel() {
      this.isModalActive = false;
    }
  }
};
</script>
