import { GetSelectLookup } from "./common";

const ResidentStatusEnum = {
  Enquiry: 1,
  ShortList: 2,
  Client: 3,
  ActiveDying: 4,
  Deceased: 5,
  Discharged: 6,
  Cancelled: 7
};

const ResidentStatusLookup = ["", "Enquiry", "Short List", "Client ", "Active Dying", "Deceased", "Discharged", "Cancelled"];

export default {
  Enum: ResidentStatusEnum,
  Select: GetSelectLookup(ResidentStatusEnum, ResidentStatusLookup),
  Lookup: ResidentStatusLookup,
  DateLabelLookup: [
    "Date",
    "Enquiry Date",
    "Date On Short List",
    "Admission Date",
    "Date",
    "Date of Death",
    "Discharge Date",
    "Date Cancelled"
  ]
};
